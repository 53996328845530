import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import ContactForm from '../components/sections/contact-form'
import HeroFullScreenPhoto from '../components/sections/hero-full-screen-photo'
import Partnerships from '../components/common/partnerships'
import OurPartners from '../components/sections/our-partners'
import Testimonials from '../components/sections/testimonials-static'
import WhyThinkPlus from '../components/sections/why-think-plus'
import SectionHeader from '../components/common/section-header'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';

import '../styles/pages/page-landingpage.scss'
import { faqPhotography } from '../utils/schema/faq'
import { photography as servicesSchema } from '../utils/schema/services'

const pageProPhotography = ({ location }) => {
  const data = useStaticQuery(graphql`{
  ghostPage: ghostPage(tags: {elemMatch: {name: {eq: "#page-pro-photography"}}}) {
    ...GhostPageFields
  }
  hero: file(relativePath: {eq: "hero/hero-prophotography.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, layout: FULL_WIDTH)
    }
  }
  category1: file(relativePath: {eq: "prophotography/Category1.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  category2: file(relativePath: {eq: "prophotography/Category2.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  category3: file(relativePath: {eq: "prophotography/Category3.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)
	const page = data.ghostPage

  return <>
      <MetaData
        data={data}
        location={location}
        type="website"
      />
      <Helmet>
        <style type="text/css">{`${page.codeinjection_styles}`}</style>
        <script type="application/ld+json">{JSON.stringify(faqPhotography)}</script>
        <script type="application/ld+json">{JSON.stringify(servicesSchema)}</script>
      </Helmet>
      <Layout viewport={'full'}>
        <article className="landing-page">
          <HeroFullScreenPhoto
            heroImage={data.hero.childImageSharp.gatsbyImageData}
            heroContent={
              `<div class="landing-page__heading">
                <h1>ΦΩΤΟΓΡΑΦΙΑ | PHOTOGRAPHY</h1>
                <h2>Εστιάζουμε στην επιτυχία σας</h2>
              </div>
              <div class="landing-page__heading-cta">
                <p class="h6 dec-element--line-horizontal">Επικοινωνήστε μαζί μας +30 210 6101478 <a href="tel: +302106101478" class="btn__theme ml-md-3 mt-3 mt-md-0">Καλέστε μας</a></p>
                <p class="h6 dec-element--line-horizontal">Ή συμπληρώστε τα πεδία της φόρμας για να επικοινωνήσουμε άμεσα μαζί σας <a href="#contact_form_section" class="btn__theme ml-md-3 mt-3 mt-md-0">Φόρμα Επικοινωνίας</a></p>
              </div>`
            }
          />
          <section className="landing-page__info background__theme text-align-center">
            <div className="container medium">
              <div className="row">
                <div className="col mb-5">
                  <h2><strong>Επαγγελματική φωτογράφιση</strong></h2>
                  <p className="h6">Μία μέθοδος για ισχυρό brand</p>
                </div>
              </div><div className="row">
                <div className="col-md-4 offset-md-4">
                  <h3 className="h6">
                    <strong className="h2">161%</strong> <br />
                    Αύξηση μετατροπής σε πωλήσεις
                    </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <h3 className="h6">
                    <strong className="h2">90%</strong> <br />
                    Βελτίωση της εμπειρίας των χρηστών και της αφοσίωσης στο brand
                  </h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6">
                    <strong className="h2">40%</strong> <br />
                    αύξηση share στα social media συγκριτικά με άλλες μορφές περιεχομένου
                  </h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6">
                    <strong className="h2">75%</strong> <br />
                    των αγορών online στηρίζεται σε εικόνες προϊόντων
                  </h3>
                </div>
              </div>
            </div>
          </section>
          <section className="why-wordpress">
            <div className="container medium">
              <header className="pro-photography text-align-center">
                <p className="h5">Γιατί είναι τόσο σημαντική</p>
                <h2 className="dec-header--underline">η επαγγελματική φωτογραφία</h2>
                <p className="h5">για την επιχείρησή σας;</p>
              </header>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-8">
                    <ul className="pro-photography">
                      <li className="dec-element--line-horizontal">Πείτε χίλιες λέξεις σε ένα δευτερόλεπτο</li>
                      <li className="dec-element--line-horizontal">Η φωτογραφία αντιπροσωπεύει το brand σας</li>
                      <li className="dec-element--line-horizontal">Οι πελάτες θέλουν να γνωρίσουν εσάς και την επιχείρησή σας μέσα από την εικόνα</li>
                      <li className="dec-element--line-horizontal">Η ποιότητα των επαγγελματικών εικόνων είναι ασύγκριτη</li>
                      <li className="dec-element--line-horizontal">Η φωτογραφία είναι το πιο διαχειρίσιμο μέσο ενεργητικού μάρκετινγκ</li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <p className="h5 text-align-center">Για τη Think Plus η επαγγελματική φωτογράφιση αποτελεί την αυθεντική εφαρμογή της δημιουργικότητας και της καινοτομίας στη σύγχρονη αγορά.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="what-we-offer background__theme">
            <div className="container medium">
              <div className="text-align-center">
                <p className="h2 dec-header--underline">Πλεονεκτήματα Επαγγελματικής Φωτογράφισης</p>
                <p className="mt-5">Στη Think Plus γνωρίζουμε άριστα την «τέχνη και επιστήμη» της φωτογραφίας. Οι επαγγελματίες φωτογράφοι μας, θα αποτυπώσουν άρτια τα προϊόντα ή τις υπηρεσίες σας και εσείς μπορείτε να εκμεταλλευτείτε τη δύναμη της φωτογραφίας ως ένα από τα δυναμικότερα διαφημιστικά μέσα στη σύγχρονη ψηφιακή εποχή!</p>
                <p className="mb-5">Οι επαγγελματίες φωτογράφοι μας, παρέχουν όλο το φάσμα των φωτογραφικών υπηρεσιών με μία ευρεία γκάμα, από προϊοντική φωτογράφιση στο χώρο σας ή στο δικό μας studio, μέχρι φωτογράφιση χώρου και κάθε λογής φωτογραφική ανάγκη που μπορεί να έχετε. Η ομάδα της Think Plus οπλισμένη με τον αρτιότερο φωτογραφικό εξοπλισμό και εμπνευσμένη από την αποτύπωση των χιλιάδων δικών σας στιγμών είναι εδώ, για να σας προσφέρει μία πλήρη γκάμα φωτογραφικών υπηρεσιών, ικανών να καλύψουν κάθε σας ανάγκη.</p>
              </div>
              <div className="row checkmarks">
                <div className="col-md-6">
                  <p>Προϊοντική φωτογράφιση στο studio της Think Plus.</p>
                  <p>Φωτογράφιση στο δικό σας χώρο.</p>
                  <p>Φωτογράφιση προϊόντων για e-shop.</p>
                  <p>Φωτογράφιση προϊόντων 360.</p>
                  <p>Προϊοντική φωτογράφιση με διαφάνεια.</p>
                  <p>Επαγγελματική φωτογράφιση ghost mannequin effect.</p>
                  <p>Φωτογράφιση ρούχων με επαγγελματίες μοντέλα.</p>
                </div>
                <div className="col-md-6">
                  <p>Υπηρεσία φωτογράφισης Real Estate.</p>
                  <p>Φωτογράφιση εκδηλώσεων.</p>
                  <p>Επαγγελματική φωτογράφιση προσώπων – Πορτραίτα.</p>
                  <p>Εναέρια φωτογράφιση – Drone Photography.</p>
                  <p>Φωτογράφιση εσωτερικών χώρων.</p>
                  <p>Εύκολη προσαρμογή και ανανέωση του περιεχομένου της ιστοσελίδας.</p>
                  <p>Lifestyle photography.</p>
                </div>
              </div>
            </div>
          </section>
          <section className="landing-page__faq">
            <div className="background__theme--dark">
              <SectionHeader
                title="Photography"
                text="FAQ"
              />
            </div>
            <div className="container medium">
              <div className="row">
                <div className="col-md-6">
                  <Accordion
                    className="accordion--theme"
                    allowZeroExpanded={true}
                  >
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Tι είδους φωτογραφικές υπηρεσίες παρέχετε;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>Παρέχουμε όλων των ειδών τις φωτογραφικές υπηρεσίες:</p>
                        <ul>
                          <li>προϊοντικές φωτογραφίσεις για eshop</li>
                          <li>Φωτογραφίσεις μόδας</li>
                          <li>Φωτογραφικές υπηρεσίες για events</li>
                          <li>Επαγγελματική φωτογράφιση χώρου</li>
                          <li>Επεξεργασία φωτογραφιών</li>
                          <li>Και πολλές ακόμη που θα χαρούμε να σας πούμε από κοντά.</li>
                        </ul>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Διαθέτετε φωτογραφικό studio;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Ναι, στα κεντρικά μας γραφεία στο Μαρούσι διαθέτουμε και φωτογραφικό studio. Θα χαρούμε να σας φιλοξενήσουμε για την επόμενη σας φωτογράφιση.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Εκτός από τη φωτογραφική λήψη, κάνετε και επεξεργασία φωτογραφίας;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Παρέχουμε όλες τις φωτογραφικές υπηρεσίες και ναι φυσικά και την κατάλληλη επεξεργασία.</p></AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
                <div className="col-md-6">
                  <Accordion
                    className="accordion--theme"
                    allowZeroExpanded={true}
                  >
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Είναι έμπειρη η ομάδα σας;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Όχι μόνο είναι έμπειρη αλλά είναι και αυτή που δημιούργησε το 2009 το μεγαλύτερο φωτογραφικό portal στην Ελλάδα, το <a href="http://redcarpet.gr" title="Redcarpet" target="_blank">redcarpet.gr</a>. Δεκάδες συνεργάτες φωτογράφοι με εκατομμύρια “κλικς” εμπειρία σε κάθε φωτογραφική υπηρεσία.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Μπορούμε να δούμε δείγμα δουλειάς σας;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Φυσικά. Καλέστε μας, αναλύστε μας το είδος της φωτογραφικής υπηρεσίας που επιθυμείτε και εμείς θα σας αποστείλουμε το κατάλληλο υλικό από το portfolio μας.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Αναλαμβάνετε και όλη τη παραγωγή μίας φωτογράφισης;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Ναι ότι χρειάζεται για να πραγματοποιηθεί μία άρτια φωτογράφιση το παρέχουμε. Ρεπεράζ για την εύρεση του κατάλληλου χώρου, διεύθυνση παραγωγής, make up, hair, styling, μοντέλο και φυσικά κάθε είδους επιθυμητός εξοπλισμός.</p></AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          </section>
          <Partnerships />
          <OurPartners headerTitle="ΠΕΛΑΤΕΣ" />
          <Testimonials classname="background__theme--dark" />
          <section className="landing-page__case-studies background__theme">
            <div className="row no-gutters align-items-center">
              <div className="col-md-6 order-2 order-md-0">
                <div className="case-study__content text-align-right dec-element--line-horizontal">
                  <h2 className="h5">Lifestyle Photography</h2>
                  <h2 className="h5">Φωτογράφιση ρούχων με επαγγελματίες μοντέλα</h2>
                </div>
              </div>
              <div className="col-md-6 order-1 order-md-0">
                <div className="case-study__photo no-padding">
                  <GatsbyImage
                    image={data.category1.childImageSharp.gatsbyImageData}
                    alt="Lifestyle photography" />
                </div>
              </div>
            </div>
            <div className="row no-gutters align-items-center">
              <div className="col-md-6">
                <div className="case-study__photo no-padding">
                  <GatsbyImage
                    image={data.category2.childImageSharp.gatsbyImageData}
                    alt="Product photography" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="case-study__content text-align-left dec-element--line-horizontal">
                  <h2 className="h5">Προϊοντική φωτογράφιση στο studio της Think Plus</h2>
                  <h2 className="h5">Φωτογράφιση στο δικό σας χώρο</h2>
                  <h2 className="h5">Φωτογράφιση προϊόντων για e-shop</h2>
                  <h2 className="h5">Επαγγελματική φωτογράφιση ghost mannequin effect</h2>
                </div>
              </div>
            </div>
            <div className="row no-gutters align-items-center">
              <div className="col-md-6 order-2 order-md-0">
                <div className="case-study__content text-align-right dec-element--line-horizontal">
                  <h2 className="h5">Φωτογράφιση εκδηλώσεων</h2>
                  <h2 className="h5">Φωτογράφιση εσωτερικών χώρων</h2>
                </div>
              </div>
              <div className="col-md-6 order-1 order-md-0">
                <div className="case-study__photo no-padding">
                  <GatsbyImage
                    image={data.category3.childImageSharp.gatsbyImageData}
                    alt="Facility photography" />
                </div>
              </div>
            </div>
          </section>
          <WhyThinkPlus />
          <ContactForm />
        </article>
      </Layout>
    </>;
}

export default pageProPhotography
